export const title = 'Ph.D. Staff Optical Engineer';
export const name = 'Mustafa Mert Bayer';
export const mail = 'mertbayer@gmail.com';
export const homeIntroduction = 'Highly proficient Staff Optical Engineer with a Ph.D. in Electrical Engineering, specializing in lidar system design and advanced optical receivers. Over 7 years of experience in designing, developing, and testing cutting-edge lidar, optical, and electro-optical systems for space and automotive applications. Published over 30 scientific papers and holds a U.S. patent for lidar innovations. Proficient in crossdisciplinary collaboration and applying advanced engineering solutions to real-world challenges.';


const galleryItems = [
  {
    link: '/assets/gallery/gallery-1.jpg',
  },

]

export default galleryItems;

export const navItems = [
  { name: 'Homepage', path: '/' },
  { name: 'Work Experience', path: '/workexperience' },
  { name: 'Research & Teaching Experience', path: '/researchprojects&teachingexperience' },
  { name: 'Publications', path: '/publications' },
  { name: 'Contact', path: '/contact' },
];

export const education = [
  {
    school: "Ph.D.: University of California, Irvine, CA",
    dateRange: "2017 – 2022",
    department: "Electrical Engineering and Computer Science",
  },
  {
    school: "MSc: Koc University, Turkey",
    dateRange: "2015 – 2017",
    department: "Optoelectronics and Photonics Engineering",
  },
  {
    school: "BS: Koc University, Turkey",
    dateRange: "2010 – 2015",
    department: "Double Major in Physics & Electrical Engineering",
  },
];

export const awards = [
  {
    dateRange: "Sept.2015 – Nov.2016",
    award: "TUBITAK Research Scholarship",
  },
  {
    dateRange: "2014, 2013, and 2010",
    award: "Koc University Dean’s Honor Roll",
  },
  {
    dateRange: "Fall 2012",
    award: "Koc University 2012 Spring Vehbi Koc Honors Reward",
  },
];

export const additionalInfo = [
  {
    title: "EDITORIAL AND REVIEW ACTIVITIES",
    desc: [
      {
        descriptionDetail: "Manuscript reviewing for journals such as Applied Optics, Optics Express, Optics Letters, OSA Continuum, and IEEE Transactions on Communications.",
        date: "Jan. 2019 – Present	",
      }
    ]
  },
  {
    title: "MEMBERSHIPS",
    desc: [
      {
        descriptionDetail: "Optica, SPIE, and IEEE membership & reviewer.",
        date: "Feb. 2016 – Present",
      },
      {
        descriptionDetail: "Optical Society of America Koc University Student Chapter Vice President.",
        date: "Feb. 2016 – Aug.2017",
      }
    ]
  },
  {
    title: "LANGUAGES",
    desc: [
      {
        descriptionDetail: "English, German (A2 Level), and Turkish (Native)",
        date:""
      }
    ]
  },
  {
    title: "SOFTWARE KNOWLEDGE",
    desc: [
      {
        descriptionDetail: "MATLAB, Python, Lab View, OptSim, Zemax, VPI Optical Design Software, Raspberry Pi+3, MS Office",
        date:""
      }
    ]
  },
];

export const articles = [
  {
    name: "Optics Express - 3D coherent single shot lidar imaging beyond coherence length",
    title: "Ataberk Ayalar, Christian Joseph Margison, Mustafa Mert Bayer, Xun Li, Ozan Berk Boyraz, Ozdal Boyraz",
    link: "https://opg.optica.org/oe/fulltext.cfm?uri=oe-32-23-40783&id=561885",
    pdf: '/assets/publications/Optics Express - 3D coherent single shot lidar imaging beyond coherence length.pdf',
    image: '/assets/publicationImages/Optics Express - 3D coherent single shot lidar imaging beyond coherence length.jpg',
  },
  {
    name: "Ranging and velocimetry measurements by phase-based MTCW lidar",
    title: "Mustafa Mert Bayer, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:hqOjcs7Dif8C",
    pdf: '/assets/publications/Ranging and velocimetry measurements by phase-based MTCW lidar.pdf',
    image: '/assets/publicationImages/Ranging and velocimetry measurements by phase-based MTCW lidar.jpg',
  },
  {
    name: "Single-shot ranging and velocimetry with a CW lidar far beyond the coherence length of the CW laser",
    title: "Mustafa Mert Bayer, Xun Li, George Nikolaev Guentchev, Rasul Torun, Jose E Velazco, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:3fE2CSJIrl8C",
    pdf: '/assets/publications/Single-shot Ranging and Velocimetry far beyond coherence length of a CW laser.pdf',
    image: '/assets/publicationImages/Single-shot Ranging and Velocimetry far beyond coherence length of a CW laser.jpg',

  },
  {
    name: "Simultaneous ranging and velocimetry with multi-tone continuous wave lidar",
    title: "Mustafa Mert Bayer, Rasul Torun, Xun Li, Jose E Velazco, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:roLk4NBRz8UC",
    pdf: '/assets/publications/Simultaneous ranging and velocimetry with multi-tone continuous wave lidar.pdf',
    image: '/assets/publicationImages/Simultaneous ranging and velocimetry with multi-tone continuous wave lidar.jpg',

  },
  {
    name: "Realization of multitone continuous wave LiDAR",
    title: "Rasul Torun, Mustafa M Bayer, Imam U Zaman, Jose E Velazco, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:WF5omc3nYNoC",
    pdf: '/assets/publications/Realization of multitone continuous wave LiDAR.pdf',
    image: '/assets/publicationImages/Realization of multitone continuous wave LiDAR.jpg',

  },
  {
    name: "Phase-Based Multi-Tone CW Lidar: A Technique for Ranging Beyond the Coherence Length of the CW Laser",
    title: "Mustafa Mert Bayer, Xun Li, Ataberk Atalar, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&cstart=20&pagesize=80&citation_for_view=p-DN1psAAAAJ:ULOm3_A8WrAC",
    pdf: '/assets/publications/Phase-Based Multi-Tone CW Lidar A Technique for Ranging Beyond the Coherence Length of the CW Laser.pdf',
    image: '/assets/publicationImages/Phase-Based Multi-Tone CW Lidar A Technique for Ranging Beyond the Coherence Length of the CW Laser.jpg',

  },
  {
    name: "A basic approach for speed profiling of alternating targets with photonic doppler velocimetry",
    title: "Mustafa Mert Bayer, Rasul Torun, Imam Uz Zaman, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:YsMSGLbcyi4C",
    pdf: '/assets/publications/A basic approach for speed profiling of alternating targets with photonic doppler velocimetry.pdf',
    image: '/assets/publicationImages/A basic approach for speed profiling of alternating targets with photonic doppler velocimetry.jpg',

  },
  {
    name: "Enhancing the multi-tone continuous-wave lidar with phase detection",
    title: "M Mert Bayer, George N Guentchev, Xun Li, Jose E Velazco, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:5nxA0vEk-isC",
    pdf: '/assets/publications/Enhancing the multi-tone continuous-wave lidar with phase detection.pdf',
    image: '/assets/publicationImages/Enhancing the multi-tone continuous-wave lidar with phase detection.jpg',

  },
  {
    name: "Photonics PNT based on multi-tone continuous wave ranging",
    title: "Mustafa Mert Bayer, Ataberk Atalar, Xun Li, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:Zph67rFs4hoC",
    pdf: '/assets/publications/Photonics PNT based on multi-tone continuous wave ranging.pdf',
    image: '/assets/publicationImages/Photonics PNT based on multi-tone continuous wave ranging.jpg',

  },
  {
    name: "Laser-inscribed diamond waveguide resonantly coupled to diamond microsphere",
    title: "Nurperi Yavuz, Mustafa Mert Bayer, Hüseyin Ozan Ҫirkinoğlu, Ali Serpengüzel, Thien Le Phu, Argyro Giakoumaki, Vibhav Bharadwaj, Roberta Ramponi, Shane M Eaton",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:Se3iqnhoufwC",
    pdf: '/assets/publications/Laser-inscribed diamond waveguide resonantly coupled to diamond microsphere.pdf',
    image: '/assets/publicationImages/Laser-inscribed diamond waveguide resonantly coupled to diamond microsphere.jpg',

  },
  {
    name: "Design and prototype of auto-track long-range free-space optical communication",
    title: "Xun Li, Mustafa Mert Bayer, George Nikolaev Guentchev, Ozdal Boyraz",
    link: "https://scholar.google.com/citations?view_op=view_citation&hl=nl&user=p-DN1psAAAAJ&citation_for_view=p-DN1psAAAAJ:KlAtU1dfN6UC",
    pdf: '/assets/publications/Design and prototype of auto-track long-range free-space optical communication.pdf',
    image: '/assets/publicationImages/Design and prototype of auto-track long-range free-space optical communication.jpg',

  },
  {
    name: "Multi-tone continuous wave detection and ranging",
    title: "Mustafa Mert Bayer, Ozdal Boyraz",
    link: "https://scholar.google.com.tr/citations?view_op=view_citation&hl=en&user=p-DN1psAAAAJ&sortby=pubdate&citation_for_view=p-DN1psAAAAJ:qxL8FJ1GzNcC",
    pdf: '/assets/publications/Multi tone continuous wave detection and ranging.pdf',
    image: '/assets/publicationImages/Multi-tone continuous wave detection and ranging.JPG',

  },
  {
    name: "Demonstration of Optical Clock-Free Localization and Navigation (CLAN)",
    title: "Mustafa Mert Bayer, Ataberk Atalar, Xun Li, Haoyu Xie, Ozdal Boyraz",
    link: "https://scholar.google.com.tr/citations?view_op=view_citation&hl=en&user=p-DN1psAAAAJ&sortby=pubdate&citation_for_view=p-DN1psAAAAJ:Wp0gIr-vW9MC",
    pdf: '/assets/publications/Demonstration_of_Optical_Clock-Free_Localization_and_Navigation_CLAN.pdf',
    image: '/assets/publicationImages/Demonstration_of_Optical_Clock-Free_Localization_and_Navigation_CLAN.JPG',

  },
  {
    name: "Femtosecond laser written diamond waveguide excitation of the whispering gallery modes in a silicon microsphere",
    title: "H. O. Çirkinoğlu, M. M. Bayer, A. Serpengüzel, S. Rampini, B. Sotillo, V. Bharadwaj, R. Ramponi, and S. M. Eaton",
    link: "https://www.sciencedirect.com/science/article/abs/pii/S0925346719300266",
    pdf: '/assets/publications/Femtosecond laser written diamond waveguide excitation of the whispering gallery modes in a silicon microsphere.pdf',
    image: '/assets/publicationImages/Femtosecond laser written diamond waveguide excitation of the whispering gallery modes in a silicon microsphere.jpg',

  },
  {
    name: "Observation of Whispering-Gallery Modes in a Diamond Microsphere",
    title: "M. M. Bayer, H. O. Çirkinoğlu, and A. Serpengüzel",
    link: "https://ieeexplore.ieee.org/document/8064736",
    pdf: '/assets/publications/Observation of Whispering Gallery Modes in a Diamond Microsphere.pdf',
    image: '/assets/publicationImages/Observation of Whispering Gallery Modes in a Diamond Microsphere.jpg',

  },
  {
    name: "Silicon microsphere whispering gallery modes excited by femtosecond-laser-inscribed glass waveguides",
    title: "H. O. Çirkinoğlu, M. M. Bayer, U. S. Gökay, A. Serpengüzel, B. Sotillo, V. Bharadwaj, R. Ramponi, and S. M. Eaton",
    link: "https://opg.optica.org/ao/abstract.cfm?uri=ao-57-14-3687",
    pdf: '/assets/publications/Silicon microsphere whispering gallery modes.pdf',
    image: '/assets/publicationImages/Silicon microsphere whispering gallery modes.jpg',

  },
  //eksik
  {
    name: "Spatial intensity profiling of elastic and inelastic scattering in isotropic and anisotropic liquids by immersion of a spherical silicon photocell",
    title: "M. H. Humayun, S. S. S. Bukhari, M. Zakwan, M. M. Bayer, U. S. Gökay, A. Serpengüzel, K. Taira, E. Omura. and J. Nakata",
    link: "https://opg.optica.org/ao/abstract.cfm?uri=ao-56-34-9384",
    pdf: '/assets/publications/Spatial intensity profiling of elastic and inelastic.pdf',
    image: '/assets/publicationImages/Spatial intensity profiling of elastic and inelastic.jpg',

  },
  //eksik
  {
    name: "Spectroscopy of a Nd: YVO4 diode pumped solid state laser",
    title: "O. M. Öztürk, M. M. Bayer, M. S. Anwar, M. Zakwan, and A. Serpengüzel",
    link: "https://scholar.google.com.tr/citations?view_op=view_citation&hl=en&user=p-DN1psAAAAJ&sortby=pubdate&citation_for_view=p-DN1psAAAAJ:mVmsd5A6BfQC",
    pdf: '/assets/publications/Spectroscopy of a Nd: YVO4 diode pumped solid state laser.pdf',
    image: '/assets/publicationImages/Spectroscopy of a Nd YVO4 diode pumped solid state laser.jpg',

  }
];

export const conferences = [
  {
    title: "3D coherent lidar imaging without coherence length limitation",
    authors: "Ataberk Atalar, Christian J Margison, M Mert Bayer, Ozan B Boyraz, Ozdal Boyraz",
    link: "/assets/conferences/3D Coherent Lidar Imaging Without Coherence Length",
  },
  {
    title: "\"Photonic Localization and Positioning using Multi-Tone Continuous-Wave Ranging Methodology,\" 2023 IEEE Aerospace Conference, Big Sky, MT, USA, 2023, pp. 1-7.",
    authors: "M. M. Bayer, A. Atalar, X. Li, H. Xie, and O. Boyraz",
    link: "/assets/conferences/Photonic Localization and Positioning using Multi-Tone Continuous-Wave Ranging Methodology.pdf"
  },
  {
    title: "\"Optical ranging and localization at beyond the coherence length of lasers,\" Proc. SPIE 12438, AI and Optical Data Sciences IV, 124380H (2023).",
    authors: "M. M. Bayer, B. U. Demirel, A. Atalar, X. Li, H. Xie, and O. Boyraz ",
    link: "/assets/conferences/Optical ranging and localization at beyond the coherence length of lasers.pdf"
  },
  {
    title: "\"Machine-learning-enhanced phase-based multi-tone continuous-wave lidar,\" Proc. SPIE 12428, Photonic Instrumentation Engineering X, 124280I (2023).",
    authors: "M. M. Bayer, Berken U. Demirel, A. Atalar, X. Li, H. Xie, and O. Boyraz ",
    link: "/assets/conferences/Machine-learning-enhanced phase-based multi-tone continuous-wave lidar.pdf"
  },
  {
    title: "\"Phase-Based Multi-Tone CW Lidar: A Technique for Ranging Beyond the Coherence Length of the CW Laser,\" in Conference on Lasers and Electro-Optics (CLEO) (2022), p. ATu4M.1.",
    authors: "M. M. Bayer, X. Li, A. Atalar, and O. Boyraz ",
    link: "/assets/conferences/Phase-Based Multi-Tone CW Lidar A Technique for Ranging Beyond the Coherence Length of the CW Laser.pdf"
  },
  {
    title: "\"Photonics PNT Based on Multi-Tone Continuous Wave Ranging,\" in Conference on Lasers and Electro-Optics, (CLEO) (2022), p. JTh3A.57.",
    authors: "M. M. Bayer, A. Atalar, X. Li, and O. Boyraz",
    link: "/assets/conferences/Photonics PNT Based on Multi-Tone Continuous Wave Ranging.pdf"
  },
  {
    title: "\"Design and Prototype of Auto-Track Long-Range Free-Space Optical Communication,\" in 2022 Optical Fiber Communications Conference and Exhibition (OFC) (2022), pp. 1–3.",
    authors: "X. Li, M. M. Bayer, G. N. Guentchev, and O. Boyraz",
    link: "/assets/conferences/Design and Prototype of Auto-Track Long-Range Free-Space Optical Communication.pdf"
  },
  {
    title: "\"Enhancing the multi-tone continuous-wave lidar with phase detection,\" Proc. SPIE 11828, 1182807 (2021). ",
    authors: "M. M. Bayer, G. N. Guentchev, X. Li, J. E. Velazco, and O. Boyraz ",
    link: "/assets/conferences/Enhancing the multi-tone continuous-wave lidar with phase detection.pdf"
  },
  {
    title: "\"Mechanical design and thermal analysis of a 12U CubeSat MTCW lidar-based optical measurement system for littoral ocean dynamics,\" Proc. SPIE 11832, 118320B (2021). ",
    authors: "G. N. Guentchev, M. M. Bayer, X. Li, and O. Boyraz ",
    link: "/assets/conferences/Mechanical design and thermal analysis of a 12U CubeSat MTCW lidar-based optical measurement system for littoral ocean dynamics.pdf"
  },
  {
    title: "\"Multi-Tone Continuous Wave Lidar in Simultaneous Ranging and Velocimetry,\" in Conference on Lasers and Electro-Optics (CLEO) (2020), p. SM1O.6. ",
    authors: "M. M. Bayer, R. Torun, I. U. Zaman, and O. Boyraz",
    link: "/assets/conferences/Multi-Tone Continuous Wave Lidar in Simultaneous Ranging and Velocimetry.pdf"
  },
  {
    title: "\"A Basic Approach for Speed Profiling of Alternating Targets with Photonic Doppler Velocimetry,\" in Conference on Lasers and Electro-Optics (CLEO) (2019), p. AW4K.4.",
    authors: "M. M. Bayer, R. Torun, I. U. Zaman, and O. Boyraz",
    link: "/assets/conferences/A Basic Approach for Speed Profiling of Alternating Targets with Photonic Doppler Velocimetry.pdf"
  },
  // link
  {
    title: "\"A Comparative Study of Inter CubeSat High-Speed Links: RF, mmWave, and Optical,\" in IEEE International Conference on Space Optical Systems and Applications (ICSOS 2019). ",
    authors: "I. U. Zaman, A. S. Behbahani, M. M. Bayer, S. Shaboyan, A. Eltawil, and O. Boyraz",
    link: "/assets/conferences/Machine-learning-enhanced phase-based multi-tone continuous-wave lidar.pdf"
  },
  {
    title: "\"Effect of Tantalum and MgO adhesion layers on plasmonic nanostructures,\" Proc. SPIE 11089, 1108916 (2019).",
    authors: "P. Sadri-Moshkenani, M. W. Khan, M. M. Bayer, M. S. Islam, E. Montoya, I. Krivortov, M. Nilsson, N. Bagherzadeh, and O. Boyraz",
    link: "/assets/conferences/Effect of Tantalum and MgO adhesion layers on plasmonic nanostructures.pdf"
  },
  {
    title: "\"Multi-tone modulated continuous-wave lidar,\" Proc. SPIE 10925, 109250V (2019).",
    authors: "R. Torun, M. M. Bayer, I. U. Zaman, and O. Boyraz",
    link: "/assets/conferences/Multi-tone modulated continuous-wave lidar.pdf"
  },
  {
    title: "\"Multi Tone Continuous Wave Lidar,\" in IEEE Photonics Society Summer Topical Meeting Series (SUM) (2019), pp. 1–2.",
    authors: "O. Boyraz, M. M. Bayer, R. Torun, and I. Zaman",
    link: "/assets/conferences/Multi Tone Continuous Wave Lidar.pdf"
  },
  // link
  {
    title: "\"Elastic Scattering from a Sapphire Microsphere in the THz Region,\" in Frontiers in Optics 2016, (FiO)(2016), p. JTh2A.180.",
    authors: "S. Sultan Shah Bukhari, M. R. Chaudhry, M. M. Bayer, and A. Serpengüzel",
    link: "/assets/conferences/Multi Tone Continuous Wave Lidar.pdf"
  },
  // link

  {
    title: "\"Mid-infrared elastic scattering from germanium microspheres,\" in International Conference on Transparent Optical Networks 2016, (ICTON) (2016), pp. 1–1.",
    authors: "M. Zakwan, M. M. Bayer, M. S. Anwar, U. S. Gökay, and A. Serpengüzel",
    link: "/assets/conferences/Multi Tone Continuous Wave Lidar.pdf"
  },
];

export const projects = [
  {
    title: "Lidar Application for CubeSat to measure littoral ocean dynamics",
    description: "In this project, we designed a 12 unit (each unit is 10x10x10cm) CubeSat for oceanography purposes. The goal is to measure the ocean surface height and wind speed and direction on the ocean.The expected resolution level is cm level at each measurement point. To achieve this, we developed a new Lidar technology called Phase-Based Multi-Tone Continuous Wave Lidar. This new technology is capable of performing high-resolution range and velocity measurements over long distances, unlike the other Lidar alternatives. The other alternatives are either power-hungry, providing smaller resolution, or not suitable for satellite systems. The developed Lidar unit is applicable for CubeSat platforms, meaning that the power, volume, weight, and stability requirements are considered during the design process. The complete CubeSat includes the power electronics, Lidar module, GPS module, CPU module, communication module, attitude module, etc.",
    images: Array.from({ length: 7 }, (_, i) => `/assets/projects/Lidar Application for CubeSat to measure littoral ocean dynamics-${i + 1}.jpg`)
  },
  {
    title: "Free Space Optical Communication",
    description: "In this project, we are targetting to establish a free-space optical communication link for <20 km distances. The designed system is capable of transmitting data at a rate of 100Gb/s. To realize this, we utilize 16 DWDM transceivers which are 10Gb/s SFP+ units. The optical link is realized via a series of control units that includes wide area APDs for coarse alignment and supervisory communication links and CMOS cameras as tracker units to establish optical communication by creating a feedback mechanism with the homemade gimbal unit.",
    images: Array.from({ length: 3 }, (_, i) => `/assets/projects/Free Space Optical Communication-${i + 1}.jpg`)
  },
  {
    title: "Amplitude-Based Multi-Tone Continuous Wave Lidar (AB-MTCW)",
    description: "A multi-tone modulated continuous wave (MTCW) Lidar technology that can provide high precision range and velocity information of static and moving targets. Here, CW lasers are simultaneously modulated with a few carefully selected RF tones. At the receiver, the interference of the scattered light with the reference is detected by a photodiode. Since, the acquired light travels to the target and back to the beam splitter, the interference signal carries the range and velocity information of the target at the amplitude and phase of the detected RF tones. We utilize the relative amplitude variations in RF tones to extract the range and velocity information.",
    images: Array.from({ length: 3 }, (_, i) => `/assets/projects/ab-mtcw-${i + 1}.jpg`)
  },
  {
    title: "Phase-Based Multi-Tone Continuous Wave (PB-MTCW Lidar)",
    description: "In this technique, instead of employing any form of frequency, phase, or amplitude sweeping, we modulate a CW laser with multiple phase-locked radio-frequency (RF) tones to generate stable sidebands using a Mach-Zehnder modulator (MZM) under a linear modulation configuration. Then we utilize the phases of individual tones that are encoded in the echo signal after heterodyning with the unmodulated local oscillator. Since the absolute value of the phase differences between the reference, i.e. local oscillator, and the echo signal are impaired due beyond the coherence length of the laser, we utilize the phase differences between RF tones that are free from common noise terms. The phase difference of the individual sidebands reveals the target distance, while the acquired Doppler shift produces the target velocity, simultaneously.",
    images: Array.from({ length: 3 }, (_, i) => `/assets/projects/hp_mtc_${i + 1}.jpg`)
  }
];


export const work = [
  {
    companyName: "Luminar Technologies, FL",
    title: "Staff Optical Engineer",
    date: "Oct 2024 – Present",
    companyImage: "/assets/gallery/luminar.png",
    companyLink: "https://www.luminartech.com/",
    descriptionDetail: [
      {
        description: "Advanced optical receiver system development for the HALO lidar family, leading high-impact projects to enhance system performance.",
        children: []
      },
      {
        description: "Designed and conducted sophisticated characterization experiments for lidar receiver subsystems using high-speed optics and electronics",
        children: []
      },
      {
        description: "Developed and implemented algorithms to evaluate receiver key performance indicators, ensuring seamless system integration.",
        children: []
      },
      {
        description: "Contributed to receiver subsystem DFMEA and collaborated with electrical design teams to optimize APD and ROIC design.",
        children: []
      }]
  },
  {
    companyName: "Luminar Technologies, FL",
    title: "Optical Engineer",
    date: "Jan. 2023 – Oct. 2024",
    companyImage: "/assets/gallery/luminar.png",
    companyLink: "https://www.luminartech.com/",
    descriptionDetail: [
      {
        description: "Designed, built, and conducted test stations and experiments to characterize and verify the specifications of the optical receiver subsystem for use in the lidar architecture.",
        children: []
      },
      {
        description: "Developed the receiver calibration process, and established receiver pass/fail criterion for the high-volume production line to improve yield.",
        children: []
      },
      {
        description: "Actively engaged in receiver-related failure analyses and created tests for root-cause diagnosis of failures. Provided feedback to improve the system design.",
        children: []
      },
      {
        description: "Conducted MATLAB-based simulations and complementary experiments to determine the required receiver specifications for optimal optical detection.",
        children: []
      },
      {
        description: "Participated in the FMEA process for the lidar receiver subsystem.",
        children: []
      },
      {
        description: "Guided the manufacturing team to improve their receiver building process via data analysis and root-cause diagnosis.",
        children: []
      },
      {
        description: "Worked in collaboration with the data science team to develop and conduct receiver-related experiments in order to aid their algorithm development and lidar sensor calibration process.",
        children: []
      }]
  },

  {
    companyName: "UC Irvine, CA",
    title: "Postdoctoral Scholar",
    date: "Sep.2022 – Jan.2023",
    companyImage: "https://apdsl.eng.uci.edu/images/uci.png",
    companyLink: "https://apdsl.eng.uci.edu/index.html",
    descriptionDetail: [

      {
        description: "Led a team to design and develop the prototype of “a CubeSat-based Optical Measurement System”, a small satellite with lidar remote sensing, funded by the Office of Naval Research.",
        link: "https://www.spiedigitallibrary.org/conference-proceedings-of-spie/11832/118320B/Mechanical-design-and-thermal-analysis-of-a-12U-CubeSat-MTCW/10.1117/12.2597709.short",
        children: []
      },
      {
        description: "Developed and patented a novel lidar technique for long-range operations.",
        children: []
      },
      {
        description: "Designed the optical transmission and reception subsystems of the lidar architecture by performing radiometric simulations.",
        children: []
      }
    ]
  },
  {
    companyName: "UC Irvine, CA",
    title: "Research Assistant",
    date: "Sep.2017 – Aug.2022",
    companyImage: "https://apdsl.eng.uci.edu/images/logo_100x100.png",
    companyLink: "https://apdsl.eng.uci.edu/index.html",
    descriptionDetail: [
      {
        description: "Developed the Phase-Based Multi-Tone Continuous Wave (MTCW) Lidar concept",
        children: [
          {
            description: "Performed simulations via MATLAB, OptSim, VPI Photonics and Zemax to prove the novel lidar concept.",
            children: []
          },
          {
            description: "Developed and conducted proof-of-concept experiments to demonstrate the MTCW lidar concept for stationary and dynamic targets.",
            children: []
          },
          {
            description: "Performed radiometric simulations to improve lidar collection in order to integrate the lidar to a CubeSat.",
            children: []
          }
        ]
      },

      {
        description: "Participated in the project “The ARA Wireless Living Lab for Smart and Connected Rural Communities”, supported by NSF.",
        link: "https://www.nsf.gov/awardsearch/showAward?AWD_ID=2130889&HistoricalAwards=false",
        children: [
          {
            description: "Performed system-level engineering to integrate all subunits effectively.",
            children: []
          },
          {
            description: "Designed the telescope unit and optical backbone of the free-space optical communication link, alongside designing and integrating the beacon signal.",
            children: []
          },
          {
            description: "Developed the system requirements and evaluated the free-space optical antenna prototype.",
            children: []
          }
        ]
      },
      {
        description: "Participated in “Omnidirectional Inter-Satellite Optical Communicator” funded by NASA.",
        link: "https://ntrs.nasa.gov/citations/20210015481",
        children: [
          {
            description: "Engineered the optical communication link budget between satellites via simulations.",
            children: []
          },
          {
            description: "Designed the optical amplifier system to meet the link budget requirements.",
            children: []
          }
        ]
      },
      {
        description: "Worked on fiber based ultra-fast mode-locked lasers.",
        children: [
          {
            description: "Performed MATLAB-based simulations to investigate the soliton explosion phenomenon in fiber based mode-locked lasers.",
            children: []
          },
          {
            description: "Developed algorithms to solve complex nonlinear differential equations by applying various numerical methods.",
            children: []
          }
        ]
      }
    ]
  },
  {
    companyName: "Koc University, Istanbul - Turkiye",
    title: "Research Assistant",
    date: "Sep.2015 – Aug.2017",
    companyImage: "https://microphotonics.ku.edu.tr/wp-content/uploads/2018/12/logo-crypto-2.jpg",
    companyLink: "https://microphotonics.ku.edu.tr/",
    descriptionDetail: [

      {
        description: "Worked as a research assistant in Koc University Microphotonics Research Lab (KUMRL).",
        children: [{
          description: "Performed research on the nitrogen-vacancy centers in natural and synthetic diamonds.",
          children: []
        },
        {
          description: "Conducted experiments on optical microcavities, and spherical resonators of various materials including diamond, silicon, and ruby.",
          children: []
        }]
      }
    ]
  },

  {
    companyName: "TÜV NORD, Istanbul - Turkiye",
    title: "Internship",
    date: "June.2014 – July.2014",
    companyImage: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Tuev-nord.svg/2560px-Tuev-nord.svg.png",
    companyLink: "https://www.tuv-nord.com/tr/tr/home/",
    descriptionDetail: [

      {
        description: "Solar panel and wind turbine inspections and certifications according to international standards in the Industrial Services department.",
        children: []
      }
    ]
  },
  {
    companyName: "FORD OTOSAN, Kocaeli - Turkiye",
    title: "Internship",
    date: "July.2013 – Aug.2013",
    companyImage: "https://hakoengineering.com/wp-content/uploads/2022/03/ford-otosan-logo.png",
    companyLink: "https://fordotosan.com.tr/tr",
    descriptionDetail: [

      {
        description: "Control systems in the automotive industry. Designing logic circuits using Logi.Cad software for relevant systems.",
        children: []
      }
    ]
  },
];


export const teachingExperience = [
  {
    schoolName: "UC Irvine, CA - Advance Photonic Devices and Systems Laboratory",
    title: "Research Assistant",
    date: "Sep.2017 – Aug.2022",
    descriptionDetail: [
      {
        description: "Research on continuous-wave and quasi-continuous wave Lidar systems, photonic Doppler velocimetry, and ranging applications for satellite and airborne systems, as well as GPS systems. System-level engineering in free-space optical communication, optical communication links, and networks. Numerical modeling of ultrafast lasers, solitons, nonlinear passive mode-locking techniques, optical amplification methods, and fiber lasers. In lab hands-on experience with optical components for testing, evaluation, and design of optical systems including lasers, detectors, RF devices, and passive optics.",
        children: []
      }]
  },


  {
    schoolName: "UC Irvine, CA",
    title: "Teaching Assistant",
    date: "Sep.2018 – Aug.2020",
    descriptionDetail: [

      {
        description: "",
        children: [{
          description: "EECS 170A – Electronics I Laboratory session TA",
          children: []
        },
        {
          description: "EECS 70A – Circuits Laboratory session and Discussion session TA",
          children: []
        },
        {
          description: "ECPS 204 – Embedded System Software Discussion TA",
          children: []
        }]
      }
    ]
  },
  {
    schoolName: "Koc University Microphotonics Research Lab (KUMRL), Istanbul-Turkey",
    title: "Research Assistant",
    date: "Sep.2015 – Aug.2017",
    descriptionDetail: [
      {
        description: "Research on the indication of nitrogen-vacancy centers in natural and synthetic diamonds. Experimenting on Optical microcavities, and spherical resonators of various materials such as diamond, silicon, and ruby.",
        children: []
      }]
  },

  {
    schoolName: "Koc University, Istanbul - Turkey",
    title: "Teaching Assistant",
    date: "May.2013 – Aug.2017",
    descriptionDetail: [

      {
        description: "",
        children: [{
          description: "PHYS 206 - Modern Physics Laboratory session TA",
          children: []
        },
        {
          description: "PHYS 405/505 - Experimental Physics Laboratory session TA",
          children: []
        }]
      }
    ]
  }

];