import React from 'react'
import { homeIntroduction } from '../data'

export default function HomeAbout() {
    return (
        <section className="uk-section uk-background-muted home-about">
            <div className="uk-container">
                <div className="uk-section uk-padding-remove-top">
                    <div className="uk-flex uk-flex-center">
                        <h1 className="uk-margin-remove">ABOUT</h1>
                    </div>
                </div>
                <div className="uk-flex-middle uk-grid-large  uk-text-center" data-uk-grid>
                    <div >
                        <p>{homeIntroduction}</p>
                        <a href="/assets/resume/Resume - Mert Bayer.pdf"  target='_blank' rel="noopener noreferrer" className='uk-button uk-button-primary' style={{ marginTop: '10px' }}>
                                            Resume
                                        </a>   <a href="/assets/resume/CV Full - Mert Bayer.pdf"  target='_blank' rel="noopener noreferrer" className='uk-button uk-button-primary' style={{ marginTop: '10px' }}>
                                           Academic CV
                                        </a>
                    </div>
                </div>
                

            </div>

        
        </section>
        
    )
}
